define("ember-modal-dialog/components/positioned-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SUPPORTED_TARGET_ATTACHMENTS = ['top', 'right', 'bottom', 'left', 'center', 'elementCenter', 'none'];

  var _default = Ember.Component.extend({
    // target - element selector, element, or Ember View
    // targetAttachment - top, right, bottom, left, center, or none
    //   left, right, top, bottom (relative to target)
    //   center (relative to container)
    targetAttachment: 'center',
    isPositioned: Ember.computed('targetAttachment', 'target', 'renderInPlace', function () {
      if (this.get('renderInPlace')) {
        return false;
      }

      var target = this.get('target');
      var targetAttachment = this.get('targetAttachment');

      if (target === 'body' && (targetAttachment === 'center' || targetAttachment === 'middle center')) {
        return false;
      }

      if (target && targetAttachment) {
        return true;
      }

      return false;
    }),
    didGetPositioned: Ember.observer('isPositioned', Ember.on('didInsertElement', function () {
      if (this._state !== 'inDOM') {
        return;
      }

      if (this.get('isPositioned')) {
        this.updateTargetAttachment();
      } else {
        this.element.style.left = '';
        this.element.style.top = '';
      }
    })),
    getWrappedTargetAttachmentElement: function getWrappedTargetAttachmentElement() {
      var target = this.get('target');

      if (!target) {
        return null;
      }

      if (Ember.typeOf(target) === 'string') {
        var targetSelector = target;
        var wrappedElement = document.querySelector(targetSelector);
        (true && !(wrappedElement) && Ember.assert("No element found for modal-dialog's target selector '".concat(targetSelector, "'."), wrappedElement));
        return wrappedElement;
      } // passed an ember view or component


      if (target.element) {
        return target.element;
      } // passed an element directly


      return target;
    },
    updateTargetAttachment: function updateTargetAttachment() {
      var targetAttachment = this.get('targetAttachment'); // Convert tether-styled values like 'middle right' to 'right'

      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      (true && !(SUPPORTED_TARGET_ATTACHMENTS.indexOf(targetAttachment) > -1) && Ember.assert("Positioned container supports targetAttachments of ".concat(SUPPORTED_TARGET_ATTACHMENTS.join(', ')), SUPPORTED_TARGET_ATTACHMENTS.indexOf(targetAttachment) > -1));
      var targetAttachmentMethod = "align".concat(Ember.String.capitalize(targetAttachment));
      var targetAttachmentElement = this.getWrappedTargetAttachmentElement();
      this[targetAttachmentMethod](targetAttachmentElement);
    },
    alignCenter: function alignCenter() {
      var elementWidth = this.element.offsetWidth;
      var elementHeight = this.element.offsetHeight;
      this.element.style.left = '50%';
      this.element.style.top = '50%';
      this.element.style.marginLeft = "".concat(elementWidth * -0.5, "px");
      this.element.style.marginTop = "".concat(elementHeight * -0.5, "px");
    },
    alignLeft: function alignLeft(targetAttachmentElement) {
      (true && !(targetAttachmentElement) && Ember.assert('Left targetAttachment requires a target', targetAttachmentElement));
      var elementWidth = this.element.offsetWidth;
      var originOffset = targetAttachmentElement.getBoundingClientRect();
      var originOffsetTop = originOffset.top;
      this.element.style.left = "".concat(originOffset.left - elementWidth, "px");
      this.element.style.top = "".concat(originOffsetTop, "px");
    },
    alignRight: function alignRight(targetAttachmentElement) {
      (true && !(targetAttachmentElement) && Ember.assert('Right targetAttachment requires a target', targetAttachmentElement));
      var targetWidth = targetAttachmentElement.offsetWidth;
      var originOffset = targetAttachmentElement.getBoundingClientRect();
      var originOffsetTop = originOffset.top;
      this.element.style.left = "".concat(originOffset.left + targetWidth, "px");
      this.element.style.top = "".concat(originOffsetTop, "px");
    },
    alignTop: function alignTop(targetAttachmentElement) {
      (true && !(targetAttachmentElement) && Ember.assert('Top targetAttachment requires a target', targetAttachmentElement));
      var elementWidth = this.element.offsetWidth;
      var elementHeight = this.element.offsetHeight;
      var originOffset = targetAttachmentElement.getBoundingClientRect();
      var originOffsetTop = originOffset.top;
      var targetWidth = targetAttachmentElement.offsetWidth;
      this.element.style.left = "".concat(originOffset.left + targetWidth / 2 - elementWidth / 2, "px");
      this.element.style.top = "".concat(originOffsetTop - elementHeight, "px");
    },
    alignBottom: function alignBottom(targetAttachmentElement) {
      (true && !(targetAttachmentElement) && Ember.assert('Bottom targetAttachment requires a target', targetAttachmentElement));
      var elementWidth = this.element.offsetWidth;
      var originOffset = targetAttachmentElement.getBoundingClientRect();
      var originOffsetTop = originOffset.top;
      var targetWidth = targetAttachmentElement.offsetWidth;
      var targetHeight = targetAttachmentElement.offsetHeight;
      this.element.style.left = "".concat(originOffset.left + targetWidth / 2 - elementWidth / 2, "px");
      this.element.style.top = "".concat(originOffsetTop + targetHeight, "px");
    },
    alignElementCenter: function alignElementCenter(targetAttachmentElement) {
      (true && !(targetAttachmentElement) && Ember.assert('ElementCenter targetAttachment requires a target', targetAttachmentElement));
      var elementWidth = this.element.offsetWidth;
      var originOffset = targetAttachmentElement.getBoundingClientRect();
      var originOffsetTop = originOffset.top;
      var targetWidth = targetAttachmentElement.offsetWidth;
      var targetHeight = targetAttachmentElement.offsetHeight;
      var elementHeight = this.element.offsetHeight;
      this.element.style.left = "".concat(originOffset.left + targetWidth / 2 - elementWidth / 2, "px");
      this.element.style.top = "".concat(originOffsetTop + targetHeight / 2 - elementHeight / 2, "px");
    },
    alignNone: function alignNone() {}
  });

  _exports.default = _default;
});