define("ember-macro-helpers/writable", ["exports", "ember-macro-helpers/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _default(getter, setterCallback) {
    var newCallback = {
      get: function get(val) {
        return val;
      }
    };

    if (setterCallback) {
      if (_typeof(setterCallback) === 'object' && setterCallback.set) {
        newCallback.set = setterCallback.set;
      } else {
        newCallback.set = function () {
          return setterCallback.apply(this, arguments);
        };
      }
    }

    return (0, _computed.default)(getter, newCallback);
  }
});