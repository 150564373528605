define('@ember-decorators/argument/-debug/helpers/one-of', ['exports', '@ember-decorators/argument/-debug/utils/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = oneOf;
  function oneOf() {
    for (var _len = arguments.length, list = Array(_len), _key = 0; _key < _len; _key++) {
      list[_key] = arguments[_key];
    }

    (true && !(arguments.length >= 1) && Ember.assert('The \'oneOf\' helper must receive at least one argument', arguments.length >= 1));
    (true && !(list.every(function (item) {
      return typeof item === 'string';
    })) && Ember.assert('The \'oneOf\' helper must receive arguments of strings, received: ' + list, list.every(function (item) {
      return typeof item === 'string';
    })));


    return (0, _validators.makeValidator)('oneOf(' + list.join() + ')', function (value) {
      return list.includes(value);
    });
  }
});